<template>
  <div class="active">
    <div class="content">
      <div class="active-top">
        <img
          :src="JSON.parse(item.templePage).activityBase.cover"
          alt=""
          class="activeImg"
        />
        <p>{{ item.num }}</p>
        <h3 class="activity-name">{{ item.name }}</h3>
        <p class="item-time">
          <i class="el-icon-time"></i>
          <span>开始时间：{{ item.beginTime }}</span>
        </p>
        <p class="item-time">
          <i class="el-icon-time"></i>
          <span>结束时间：{{ item.endTime }}</span>
        </p>
        <p class="item-time">
          <i class="el-icon-user"></i>
          <span
            >参与人数：{{ item.joinPeople
            }}{{ item.peopleLimit == -1 ? "" : "/" + item.peopleLimit }}</span
          >
        </p>
      </div>
      <div
        class="bindID"
        v-if="id"
        @click="
          $emit(
            'relation',
            item.id,
            id == item.id ? 0 : 1
          )
        "
      >
        <img src="@/assets/img/OwnWorkbench/relation.png" alt="" />
        {{
          id == item.id
            ? "当前活动已绑定,点击取消绑定"
            : "绑定此活动"
        }}
      </div>
      <div class="active-btn">
        <ul>
          <li class="controlBtn" @click="toRelease()" v-if="item.activityStatus == 0">
            <i class="el-icon-s-promotion"></i>
            <p>发布</p>
          </li>
          <li class="controlBtn" @click="toActivityData()" v-else>
            <i class="el-icon-s-data"></i>
            <p>数据</p>
          </li>
          <li class="controlBtn" @click="toRelease()">
            <i class="el-icon-view"></i>
            <p>预览</p>
          </li>
          <li class="controlBtn" @click="editActive()">
            <i class="el-icon-edit-outline"></i>
            <p>编辑</p>
          </li>
          <li class="controlBtn">
            <el-dropdown class="icon more">
              <span class="el-dropdown-link">
                <i class="el-icon-more"></i>
                <p>更多</p>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="item.templeType == 24"
                ><div @click="toScreen()">大屏</div></el-dropdown-item
                >
                <el-dropdown-item v-if="item.activityStatus == 1"
                ><div @click="activeStart()">开始</div></el-dropdown-item
                >
                <el-dropdown-item v-else-if="item.activityStatus == 2"
                  ><div @click="activeEnd()">结束</div></el-dropdown-item
                >
                <el-dropdown-item v-if="item.activityStatus == 2 && item.status"
                  ><div @click="activeStop()">暂停</div></el-dropdown-item
                >
                <el-dropdown-item v-else-if="item.activityStatus == 2 && !item.status"
                  ><div @click="activeUp()">启动</div></el-dropdown-item
                >
                <el-dropdown-item
                  ><div @click="activeDel()">删除</div></el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </div>
      <i class="active-status" v-if="item.activityStatus === 2 && item.status">进行中</i>
      <i class="active-status activeNoUse" v-else-if="item.activityStatus === 2 && !item.status">已暂停</i>
      <i class="active-status activeNoUse" v-else-if="item.activityStatus === 0">未发布</i>
      <i class="active-status activeNoUse" v-else-if="item.activityStatus === 1">未开始</i>
      <i class="active-status activeEnd" v-else-if="item.activityStatus === 3">已结束</i>
      <!-- <i class="active-status" v-else-if="item.activityStatus == 5 && m">{{
        m + " : " + s
      }}</i> -->
    </div>

    <!-- 预览发布 -->
    <el-dialog
      title="预览和发布"
      :visible.sync="visible"
      width="40%"
      class="preview"
      append-to-body
    >
      <div
        class="rightImg"
      >
        <img
          src="../../assets/img/marketing/title.png"
          alt=""
          width="100%"
          class="titleImg"
        />
        <div class="title-name">
          <i class="el-icon-close"></i>
          <span>{{ item.name }}</span>
          <i class="el-icon-more"></i>
        </div>
        <img
          :src="JSON.parse(item.templePage).activityBase.cover"
          alt=""
          class="contentImg"
        />
        <div class="codeImg">
          <div class="tryActive">
            <active-qrcode
              :url="h5Url"
              class="codeUrl"
              codeSize="180"
            ></active-qrcode>
            <p>扫二维码体验活动</p>
          </div>
        </div>
      </div>
      <div class="leftCode">
        <div class="code">
          <div class="codeImg">
            <active-qrcode
              :url="h5Url"
              class="codeUrl"
              codeSize="180"
            ></active-qrcode>
            <p>
              微信扫一扫
              <el-button type="text" @click="downloadCode"
                >下载二维码</el-button
              >
            </p>
          </div>
        </div>
        <div class="code-text">
          <p>活动连接</p>
          <el-input class="activeUrl" v-model="h5Url"></el-input>
          <el-button
            v-clipboard:error="onError"
            v-clipboard:copy="h5Url"
            v-clipboard:success="onCopy"
            >复制</el-button
          >
          <div class="btn">
            <el-button type="primary" @click="publish" v-if="isRelease">
              <i class="el-icon-s-promotion"></i>
              马上发布
            </el-button>
            <el-button @click="editActive()">
              <i class="el-icon-edit-outline"></i>
              继续编辑
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 发布信息确认 -->
    <el-dialog title="确认发布" :visible.sync="visible3" width="25%">
      <p>活动开始时间: {{ item.beginTime }}</p>
      <p class="activeTime">活动结束时间: {{ item.endTime }}</p>
      <p style="margin-left: 28px">活动名称: {{ item.name }}</p>
      <p class="activeTips">发布后奖项类型和生成券号将不能修改</p>
      <div class="activeBtn">
        <el-button type="primary" @click="getInfo">确认发布</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </el-dialog>

    <!-- 绑定公众号 -->
    <el-dialog title="绑定公众号" :visible.sync="visible1" width="20%">
      <div style="text-align: center; border: 1px solid #ccc">
        <div
          style="
            width: 200px;
            height: 200px;
            text-align: center;
            display: inline-block;
          "
        >
          <img
            :src="qrcodeUrl"
            alt=""
            class="qrcode"
            v-if="qrcodeUrl"
            @click="refresh"
            width="200px"
          />
        </div>
        <p>微信扫一扫</p>
        <h6 style="margin: 10px 0">请先绑定无限立方公众号</h6>
        <p>绑定后，可在微信中收取活动关键信息（参与人数、奖项数据）等</p>
      </div>
    </el-dialog>
    <!-- 绑定手机 -->
    <el-dialog title="绑定手机" :visible.sync="visible2" width="28%" center="">
      <p style="margin-bottom: 20px">
        为了您的账号安全，请绑定手机，同时该手机号也可以作为登录账号使用
      </p>
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item prop="phone">
          <el-input
            v-model="form.phone"
            placeholder="请输入手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-col :span="18">
            <el-form-item prop="code">
              <el-input
                v-model="form.code"
                placeholder="请输入短信验证码"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-button
                type="primary"
                @click="sendCode"
                v-if="isSend"
                class="sendCode"
                style="width: 100%"
                >发送验证码</el-button
              >
              <el-button
                type="primary"
                class="sendCode"
                disabled
                v-else
                style="width: 100%"
                >{{ this.countdown + "s" }}</el-button
              >
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item>
          <div style="text-align: center">
            <el-button
              type="primary"
              size="medium"
              class="toPhoneLogin"
              @click="toBind"
              @keydown="toBind"
              >立即绑定</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 模板付费 -->
    <el-dialog :visible.sync="visiblePay" :show-close="false" class="pay">
      <div class="pay-header">
        <div class="pay-title">购买付费活动模板</div>
        <div class="pay-tips">一次购买，永久使用</div>
        <div class="cancel" @click="visiblePay = false">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <div class="pay-info" v-if="item.templePage">
        <ul class="pay-text">
          <li class="pay-item">
            <div class="pay-key">模板类型</div>
            <div class="pay-value">{{ typeList[item.templeType] }}</div>
          </li>
          <li class="pay-item">
            <div class="pay-key">模板名称</div>
            <div class="pay-value">{{ item.name }}</div>
          </li>
          <li class="pay-item">
            <div class="pay-key">模板金额</div>
            <div class="pay-value">{{ parseFloat(JSON.parse(item.templePage).activityBase.sellingPrice).toFixed(2) + '元' }}</div>
          </li>
          <li class="pay-item">
            <div class="pay-key">优惠卷</div>
            <div class="pay-value">
              <span class="pay-coupon">无</span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </li>
          <li class="pay-item">
            <div class="pay-real">实付金额</div>
            <div class="pay-num">{{ parseFloat(paymentInfo.payAmount).toFixed(2) + '元' }}</div>
          </li>
        </ul>
      </div>
      <div class="code">
        <!-- <p>请使用{{ current ? '支付宝' : '微信' }}扫码支付</p> -->
        <p>请使用微信扫码支付</p>
        <div class="code-content">
          <active-qrcode :url="paymentInfo.codeUrl" codeSize="136"></active-qrcode>
        </div>
        <ul class="btn">
          <li class="btn-item" v-for="(item, index) in payWayList" :key="index" @click="setPayWay(index)" :class="{active: index == current}">{{ item }}</li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import listNull from '@/components/listNull.vue'
import { deleteEvent } from '@/api/active.js'
import { updateStatus, activityStart, activityEnd, isCalData, isPay,
 payInfo, payStatus, activityStop, activityUp, process2Publish } from '@/api/market'
import ActiveQrcode from '@/components/ActiveQrcode.vue'
import { getPublicUrl, getPhoneCode } from '@/api/login'
import { getUserInfo, bindPhone, bindWx } from '@/api/userInfo'
import { getRoleIds, downLoadFile } from '@/lib/util'
import { timestampToTime } from '@/filter/filter'

export default {
  props: ['id', 'item'],
  components: {
    listNull,
    ActiveQrcode
  },
  watch: {
    code (val, oldval) {
      if (val === 0) {
        this.$message.success('绑定成功')
        this.release()
        // this.$router.push('/OwnWorkbench/OwnActivity')
      } else if (val === 666) {
        this.$message('该二维码已过期，请点击二维码刷新！')
      }
      clearInterval(this.timeId)
    },
    visible1 (val) {
      if (!val) {
        clearInterval(this.timeId)
      }
    },
    visiblePay(val) {
      if (!val) {
        clearInterval(this.payID);
      }
    },
    // 'item.activityStatus' (val) {
    //   if (val == 5) {
    //     this.countTime(new Date(this.item.beginTime).getTime() - new Date().getTime())
    //   }
    // }
  },
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else {
        var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的手机号'))
        }
      }
    }
    var validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入短信验证码'))
      } else {
        var reg = /^\d{6}$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的短信验证码'))
        }
      }
    }
    return {
      visible: false,
      visible1: false,
      visible2: false,
      visible3: false,
      isSend: true,
      uuid: '',
      qrcodeUrl: '',
      expireSeconds: '',
      code: '',
      timeId: '',
      m: '',
      s: '',
      countdown: '',
      isRelease: true,
      isShowCodeImg: false,
      activeListData: [],
      countTimeID: '',
      form: {
        phone: '',
        code: ''
      },
      visiblePay: false,
      rules: {
        phone: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        code: [
          { required: true, validator: validateCode, trigger: 'blur' }
        ]
      },
      h5Url: `${this.$url}/cube_web_api/auth/authWeChatUrl?userId=`, // 微信扫码进入H5页面
      // payWayList: ['微信支付', '支付宝'],
      payWayList: ['微信支付'],
      current: 0,
      typeList: ['开宝箱', '九宫格', '砸金蛋', '大转盘', '小兔兔奔月记', '红旗护卫', '画金蛋', '幸运大翻转', '欢乐抽奖机', '阅读有礼'],
      paymentInfo: {},
      payID: null
    }
  },
  // created () {
  //   if (this.item.activityStatus == 5) {
  //     this.countTime(new Date(this.item.beginTime).getTime() - new Date().getTime())
  //   }
  // },
  methods: {
    // 马上发布
    async publish () {
		const res = await isPay({templeId: this.item.templeId})
		if(res.code === 8016) {
			this.getPayInfo(1)
		}else if(res.code === 200) {
			this.visible3 = true
		}else {
			this.$message.error(res.msg)
		}
    },
    // 获取支付信息 1:微信支付 2:支付宝支付
    async getPayInfo (payType) {
      const res = await payInfo({templeId: this.item.templeId, payType})
      if(res.code === 200) {
        this.paymentInfo = res.data
        this.visiblePay = true
        this.payID = setInterval(() => {
          this.getPayStatus()
        }, 3000);
      }
    },
    // 支付状态查询
    async getPayStatus () {
      const res = await payStatus({orderNo: this.paymentInfo.orderNo})
      if(res.data === 1) {
        clearInterval(this.payID)
        this.payID = null
        this.$message.success('支付成功')
        this.visiblePay = false
        this.visible3 = true
      }
    },
    // 选择支付方式
    setPayWay (index) {
      // clearInterval(this.payID)
      // this.payID = null
      // if(this.current !== index) {
      //   this.current = index
      //   this.getPayInfo(index + 1)
      // }
      if(index === 1) {
        this.$message('暂不支持此支付方式')
      }
    },
    // 下载二维码
    downloadCode () {
      if (this.h5Url) {
        const imgURL = document.getElementsByTagName('canvas')[0].toDataURL('image/jpg')
        downLoadFile(imgURL, 'png')
      }
    },
    cancel () {
      this.visible3 = false
      this.visible = false
    },
    // 开始
    activeStart () {
      this.$confirm('您确定要提前开始此活动吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async () => {
        const res = await activityStart({ id: this.item.id })
        if (res.code == 200) {
          this.$message.success('活动将在十分钟后开始')
          this.$emit('queryActive')
        }
      }).catch(() => { })
    },
    // 前往大屏
    toScreen () {
      const { href } = this.$router.resolve({ path: '/largeScreen', query: { activityId: this.item.id,activityStatus: this.item.activityStatus,templeName: this.item.name } })
      window.open(href, '_blank')
    },
    // 结束
    activeEnd () {
      this.$confirm('您确定要提前结束活动吗？活动结束后，活动数据会保留。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async () => {
        const res = await activityEnd({ id: this.item.id })
        if (res.code == 200) {
          this.$message.success('活动已结束')
          this.$emit('queryActive')
        }
      }).catch(() => { })
    },
    // 暂停
    activeStop () {
      this.$confirm('您确定要暂停活动吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async () => {
        const res = await activityStop({ id: this.item.id })
        if (res.code == 200) {
          this.$message.success('活动已暂停')
          this.$emit('queryActive')
        }
      }).catch(() => { })
    },
    // 启动
    activeUp () {
      this.$confirm('您确定要启动活动吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async () => {
        const res = await activityUp({ id: this.item.id })
        if (res.code == 200) {
          this.$message.success('活动已启动')
          this.$emit('queryActive')
        }
      }).catch(() => { })
    },
    // 删除
    activeDel () {
      if (this.item.activityStatus == 2) {
        this.$message.error(' 活动尚未结束，无法删除，请提前结束活动')
      } else {
        this.$confirm('您确定要删除此活动吗？删除后数据将无法找回。', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          deleteEvent({ id: this.item.id }).then(res => {
            // console.log(res)
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功'
              })
              this.$parent.pageIndex = 1
              this.$emit('queryActive')
            } else {
              this.$message.error(res.msg)
            }
          })
        }).catch(() => { })
      }
    },
    toActivityData () {
      const { href } = this.$router.resolve({
        name: 'ActivityData',
        query: {
          activityId: this.item.id,
          activityStatus: this.item.activityStatus,
          templeType: this.item.templeType
          // isRun: this.item.isPublish
        },
        params: {
          status: this.item.activityStatus
        }
      })
      window.open(href, '_blank')
    },
    toRelease () {
      this.h5Url = `${this.$url}/cube_web_api/auth/authWeChatUrl?userId=`
      const redirectUrl = encodeURIComponent(`${process.env.VUE_APP_WECHAT}/#/pages/shareIn/shareIn?activity=` + this.item.id)
      this.h5Url = this.h5Url + getRoleIds().userId + '&redirectUrl=' + redirectUrl
      this.visible = true
      this.isRelease = this.item.activityStatus == 0
      // const res = await updateStatus({id, enable: true })
      // console.log(res)
    },
    // 复制成功
    onCopy (e) {
      this.$message({
        message: '复制成功！',
        type: 'success'
      })
    },
    // 复制失败
    onError (e) {
      this.$message({
        message: '复制失败！',
        type: 'error'
      })
    },
    // 发布
    async release () {
		let res;
		if(this.item.templeType == 10){
			this.$prompt('请输入钉钉审批说明', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				inputPattern: /^[^\s]+[\s]*.*$/,
				inputErrorMessage: '审批说明不能为空'
			}).then(async({ value }) => {
				res = await process2Publish({ id: this.item.id, processRemark: value})
				this.result(res)
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '取消输入'
				});
			});
		} else {
			res = await updateStatus({ id: this.item.id})
			this.result(res)
		}
    },
	result(res){
		if (res.code == 200) {
			this.$message.success(res.msg)
			this.visible = false
			this.visible1 = false
			this.visible2 = false
			this.visible3 = false
			this.$emit('queryActive')
		} else {
			this.$message.error(res.msg)
			this.visible3 = false
		}
	},
    // 编辑
    async editActive () {
      sessionStorage.removeItem('data')
      const res = await isCalData({id: this.item.id})
      if (res.code == 200) {
		console.log('this.item:',this.item)
        const { href } = this.$router.resolve({ path: '/MarketingActivityEdit', query: { activityId: this.item.id,activityStatus: this.item.activityStatus,templeName: this.item.name } })
        window.open(href, '_blank')
      } else if(res.code == 8013) {
        this.$message('物资结算中,请稍后再编辑...')
      }
    },
    // 获取uuid
    getUuid (uuid, isWeb) {
      const idArr = []
      const hexDigits = '0123456789abcdef'
      for (let i = 0; i < 32; i++) {
        idArr[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
      }
      if (isWeb) {
        return uuid = idArr.join('')
      } else {
        return uuid = 'web_' + idArr.join('')
      }
    },
    // 获取二维码
    async getQrcode () {
      this.uuid = this.getUuid(this.uuid)
      const res = await getPublicUrl({ sceneId: this.uuid })
      if (res && res.code === 0) {
        this.expireSeconds = new Date().getTime() + res.data.expireSeconds * 1000
        this.qrcodeUrl = res.data.qrCode
        this.sweepCode()
      }
    },
    // 扫码
    async sweepCode () {
      this.timeId = setInterval(async () => {
        const res = await bindWx({ sceneId: this.uuid })
        if (res.code == 0) {
          this.code = 0
        } else if (res.code == 500) {
          this.$message.error('该微信已绑定其他账号')
          this.refresh()
        } else {
          if (new Date().getTime() > this.expireSeconds) {
            this.code = 666
          }
        }
      }, 5000)
    },
    // 刷新二维码
    refresh () {
      clearInterval(this.timeId)
      this.getQrcode()
    },
    // 发送验证码
    async sendCode () {
      const params = JSON.stringify(this.form)
      if (!this.form.phone) {
        this.$message('请输入正确的手机号')
      } else {
        const res = await getPhoneCode(params)
        if (res && res.code === 0) {
          this.isSend = false
          this.countdown = 60
          const codeId = setInterval(() => {
            if (this.countdown === 0) {
              clearInterval(codeId)
              this.isSend = true
            }
            this.countdown--
          }, 1000)
        }
      }
    },
    // 手机绑定
    toBind () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const res = await bindPhone(this.form)
          if (res.code == 0) {
            this.$message.success('绑定成功')
            this.visible2 = false
            this.release()
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    // 获取用户信息
    async getInfo () {
      const res = await getUserInfo()
      if (res.code == 0) {
        this.userInfo = res.data
        this.getBindType(this.userInfo.userAuths)
      }
    },
    // 获取绑定信息
    getBindType (userAuths) {
      const identityArr = []
      userAuths.forEach(item => {
        identityArr.push(item.identityType)
      })

      if (!identityArr.includes(1)) {
        this.visible2 = true
      } else if (!identityArr.includes(3)) {
			if(identityArr.includes(5)){
				this.release()
			} else {
				this.getQrcode()
				this.visible1 = true
			}
      } else {
		this.release()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.active {
  display: inline-block;
  .content {
    position: relative;
    width: 280px;
    height: 280px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border: 1px solid #ccc;
    margin: 0 20px 20px 0;
    transition: all 0.2s linear;
    &:hover {
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
      transform: translateY(-8px);
      .bindID {
        bottom: 70px;
        cursor: pointer;
      }
    }
    .activity-name {
      width: 60%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .active-top {
      height: 75%;
      padding: 20px;
    }
    // .relation{
    //   width: 100%;
    //   height: 40px;
    //   background-color: orange;
    //   padding: 6px 10px;
    //   position: absolute;
    //   right: 0;
    //   bottom: 0;
    //   z-index: 2;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   transition: 0.5s;
    //   color: #fff;
    //   cursor: pointer;
    //   img{
    //     width: 30px;
    //     height: 30px;
    //   }
    // }
    .active-btn {
      height: 25%;
      background-color: #f2f2f2;
      position: relative;
      z-index: 3;
      ul {
        display: flex;
        justify-content: space-around;
        height: 100%;
        align-items: center;
        li {
          cursor: pointer;
          text-align: center;
          color: #606266;
          &:hover {
            color: #3a8ee6;
          }
          p {
            font-size: 14px;
            margin-top: 5px;
          }
        }
      }
    }
    .activeImg {
      width: 50px;
      height: 50px;
      border-radius: 10px;
      float: left;
      margin-right: 10px;
    }
    .item-time {
      margin-top: 20px;
      font-size: 14px;
    }
    .active-status {
      position: absolute;
      right: 0;
      top: 20px;
      width: 60px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      display: inline-block;
      font-style: normal;
      font-size: 12px;
      color: #fff;
      background-color: #009900;
    }
    .activeNoUse {
      background-color: #ff9900 !important;
    }
    .activeEnd {
      background-color: #aeaeae !important;
    }
    .bindID {
      width: 100%;
      height: 30px;
      background-color: #ff7e28;
      color: #fff;
      line-height: 30px;
      position: absolute;
      bottom: 39px;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
      z-index: 0;
      img {
        width: 24px;
        margin-right: 10px;
      }
    }
  }
}
.preview {
  /deep/ .el-dialog__body {
    overflow: hidden;
  }
  .rightImg:hover .codeImg {
    display: block !important;
  }
  .rightImg {
    position: relative;
    float: left;
    width: 45%;
    height: 630px;
    .codeImg {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 96%;
      background-color: rgba(0, 0, 0, .3);
      text-align: center;
      .tryActive {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 220px;
        height: 250px;
        background-color: #fff;
        text-align: center;
        .codeUrl {
          display: inline-block;
          margin: 20px 0 10px 0;
        }
      }
    }
    .contentImg {
      width: 100%;
      height: 88%;
      margin-top: 21px;
    }
    .titleImg {
      width: 100%;
    }
    .title-name {
      position: absolute;
      top: 4%;
      left: 0;
      width: 100%;
      height: 30px;
      display: flex;
      font-size: 12px;
      align-items: center;
      background-color: #f2f2f2;
      span {
        flex: 1;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .leftCode {
    float: right;
    width: 45%;
    height: 630px;
    .code {
      height: 50%;
      text-align: center;
      .codeImg {
        display: inline-block;
        width: 220px;
        height: 250px;
        border: 1px solid #ccc;
        text-align: center;
        .codeUrl {
          display: inline-block;
          margin: 20px 0 10px 0;
        }
        /deep/ .el-button {
          padding: 0;
        }
      }
    }
    .code-text {
      height: 50%;
      .activeUrl {
        width: 70%;
      }
      p {
        margin-top: 20px;
      }
      .btn {
        margin-top: 70px;
        text-align: center;
      }
    }
  }
}
.activeTime {
  margin: 20px 0;
}
.activeTips {
  margin: 20px 0;
  color: #999;
}
.activeBtn {
  text-align: center;
}
.pay {
  /deep/ .el-dialog__header {
    padding: 0;
  }
  /deep/ .el-dialog {
    width: 520px;
    height: 729px;
    border-radius: 16px;
    .el-dialog__body {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }
  .pay-header {
    position: relative;
    padding-top: 24px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    // background-color: #f60;
    height: 100px;
    background: url("../../assets/img/marketing/bg.png");
    background-size: 100% 100%;
    .pay-title {
      font-size: 18px;
      color: #fff;
      font-weight: 500;
      text-align: center;
    }
    .cancel {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      padding: 4px;
      background-color: #fff;
      color: #ff7539;
      line-height: 14px;
      cursor: pointer;
    }
    .pay-tips {
      width: 244px;
      height: 30px;
      background: rgba(255, 255, 255, 0.15);
      border: 1px solid #fff;
      border-radius: 15px;
      margin: 8px auto 15px;
      text-align: center;
      line-height: 30px;
      color: #fff;
    }
  }
  .pay-info {
    padding: 59px 30px 50px;
    .pay-text {
      width: 460px;
      height: 240px;
      background: #FAFAFA;
      padding: 10px 20px;
      .pay-item {
        border-bottom: 1px solid #E7E7E7;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        color: #666;
        padding: 0 10px;
        .pay-key {
          float: left;
        }
        .pay-value {
          float: right;
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 100%;
        }
        .pay-coupon {
          width: 63px;
          height: 30px;
          background: url('../../assets/img/marketing/card_bg.png');
          background-size: 100% 100%;
          line-height: 30px;
          text-align: center;
          margin-right: 10px;
        }
        &:last-child {
          height: 60px;
          line-height: 60px;
          border: none;
        }
        .pay-real {
          float: left;
          font-weight: 500;
          color: #181818;
        }
        .pay-num {
          float: right;
          color: #EE0C19;
        }
      }
    }
  }
  .code {
    text-align: center;
    font-size: 12px;
    .code-content {
      width: 160px;
      height: 160px;
      background: url("../../assets/img/QR_bg.png");
      background-size: 100% 100%;
      margin: 12px auto;
      padding: 12px;
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    .btn-item {
      width: 80px;
      height: 30px;
      background: #E9F0FE;
      border: 1px solid #FAFAFA;
      border-radius: 4px 0px 0px 4px;
      line-height: 30px;
      cursor: pointer;
    }
    .active {
      border: 1px solid #2468F2;
      color: #2468F2;
    }
  }
}
</style>
