import Request from '@/lib/Request'
// const http = new Request('http://192.168.9.148:9003', 3000)
// const http = new Request('http://192.168.0.237:32510', 3000)
// const http = new Request('https://cubewebapi.51ylk.com', 3000)
// const http1 = new Request('http://192.168.1.144:9003',3000) // 许穗丰本地
const http = new Request(process.env.VUE_APP_URL)
// 查询活动列表
export const queryAll = params => {
  return http.post('/cube-client/activity/page', params)
}
// 删除活动
export const deleteEvent = params => {
  return http.delete('/cube-client/activity/delete', params)
}
// 关联活动
export const updateIfOpenActivity = params => {
  return http.delete('/cube_web_api/rawcode/coderelation/updateIfOpenActivity', params)
}
// 添加导出查询
export const addExport = params => {
	return http.get('/cube-client/activity/player/export/win/order', params)
}
// 获取文件列表
export const queryExportFileList = params => {
  return http.get('/cube-client/export/excel/queryFileList', params)
}
// 下载文件
export const exportFile = params => {
	// window.open('http://192.168.1.144:9003/cube-client/export/excel/download?name=' + params.name + '&token=' + localStorage.getItem('token'))
	window.open(process.env.VUE_APP_URL + '/cube-client/export/excel/download?name=' + params.name + '&token=' + localStorage.getItem('token'))
}