import Request from '@/lib/Request'
// const http = new Request('http://192.168.1.149:9003', 3000) // 吴辉
const http = new Request(process.env.VUE_APP_URL)
// const http = new Request('http://192.168.1.144:9003', 3000) // 穗丰
// 新增活动
export const dataSave = params => {
  return http.post('/cube-client/activity/add', params)
}
// 发布
export const updateStatus = params => {
  return http.put('/cube-client/activity/publish', params)
}
// 开始
export const activityStart = params => {
  return http.put('/cube-client/activity/enable', params)
}
// 结束
export const activityEnd = params => {
  return http.put('/cube-client/activity/end', params)
}
// 暂停
export const activityStop = params => {
  return http.put('/cube-client/activity/disable', params)
}
// 启动
export const activityUp = params => {
  return http.put('/cube-client/activity/enable', params)
}
// 编辑时减少奖项数量
export const editDelPrize = params => {
  return http.delete('/cube-client/activity/prize/delete', params)
}
// 查看活动结束物资返库状态
export const isCalData = params => {
  return http.get('/cube-client/activity/isCalData', params)
}
// 查看模板是否付费
export const isPay = params => {
  return http.get('/cube-client/activity/check/temple/shop', params)
}
// 获取支付信息
export const payInfo = params => {
  return http.post('/cube-client/activity/temple/shop/pay', params)
}
// 支付状态
export const payStatus = params => {
  return http.get('/cube-client/activity/temple/shop/check/status', params)
}
// ------------------------------------------自建奖品-------------------------------------
// 查询
export const getSelfPrize = params => {
  return http.post('/cube-client/product/self/page/with/stock', params)
}
// 新增
export const addSelfPrize = data => {
  return http.post('/cube-client/product/self/add', data)
}
// 根据id查找
export const getIdSelfPrize = params => {
  return http.get('/cube-client/product/self/get/one', params)
}
// 编辑
export const updateSelfPrize = data => {
  return http.put('/cube-client/product/self/update', {}, data)
}
// 禁用
export const setSelfDisable = params => {
  return http.put('/cube-client/product/self/disable', params)
}
// 启用
export const setSelfEnable = params => {
  return http.put('/cube-client/product/self/enable', params)
}
// 删除
export const delSelfPrize = params => {
  return http.delete('/cube-client/product/self/delete', params)
}

// ------------------------------------------奖品中心----------------------------------------------
// const http = new Request('http://192.168.9.150:9002', 3000)
// const http2 = new Request('http://192.168.1.65:9003', 3000)
// 获取礼品分类
export const getResourcesType = params => {
  return http.post('/cube-client/classify/page', params)
}
// 获取分类礼品信息
export const getGiftData = params => {
  return http.post('/cube-client/product/center/source/page', params)
}

// 获取礼品账户列表
export const getPrizeList = params => {
  return http.post('/cube-client/product/center/page/with/stock', params)
}

// ------------------------------------营销活动列表---------------------------------------------
// 获取模板编辑数据
export const getIdTemplateData = params => {
  return http.get('/cube-client/activity/temple/get/one', params)
}
export const getIdBuyTemplate = params => {
  return http.get('/cube-client/activity/temple/get/success/one', params)
}
// 获取活动编辑数据
export const getIdActiveData = params => {
  return http.get('/cube-client/activity/get/base/one', params)
}

// 保存编辑数据
export const updateActiveData = data => {
  return http.put('/cube-client/activity/update', {}, data)
}

// 未登录查询
export const getList = params => {
  return http.post('/cube-client/activity/temple/page', params)
}
// 登录后查询
export const getLoginList = params => {
  return http.post('/cube-client/activity/temple/logged/page', params)
}
// 检测是否为红辽用户
export const isHLAcount = params => {
  return http.post('/cube-client/activity/temple/verify/isHLAcount', params)
}
// 取消邮件发送
export const closeMailSend = params => {
  return http.get('/cube-client/activity/globalMonitor/global/closeMailSend', params)
}

// -------------------------------------------活动数据------------------------------------------
// 玩家列表
// 查
export const getPlayerList = params => {
  return http.post('/cube-client/activity/player/page', params)
}
// 移入黑名单
export const setPlayerBlacklist = params => {
  return http.put('/cube-client/activity/player/add/black', params)
}
// 移出黑名单
export const removePlayerBlacklist = params => {
  return http.put('/cube-client/activity/player/remove/black', params)
}
// 导出玩家列表
export const exportPlayerList = params => {
  return http.get('/cube-client/activity/player/export/order/player', params)
}
// 导出获奖名单
export const exportWinPlayer = params => {
  return http.get('/cube-client/activity/player/export/win/order', params)
}

// 黑名单
// 查
export const getBlacklist = params => {
  return http.post('/cube-client/activity/player/black', params)
}
// 获奖名单
// 查
export const getWinnerlist = params => {
  return http.post('/cube-client/activity/player/win/order', params)
}
// 核销
export const verification = params => {
  return http.put('/cube-client/activity/player/order/self/over', params)
}

// 奖品数据
// 查
export const getPrizeData = params => {
  return http.get('/cube-client/activity/player/prize/statistics', params)
}
// 退回资产
export const returnGoods = params => {
  return http.put('/cube-client/activity/player/order/return/product', params)
}

// 传播数据
// 查图表数据
export const getChartData = params => {
  return http.post('/cube-client/data/api/get', params)
}
// 查活动详情
export const getDetail = params => {
  return http.get('/cube-client/activity/get/one', params)
}

// -------------------------收藏------------------------------------
// 收藏
export const addCollect = params => {
  return http.post('/cube-client/activity/temple/collect/add', params)
}
// 取消收藏
export const delCollect = params => {
  return http.delete('/cube-client/activity/temple/collect/delete', params)
}
// 收藏列表
export const getCollectList = params => {
  return http.post('/cube-client/activity/temple/collect/page', params)
}
// 编辑模板
export const updataTemplateData = data => {
  return http.put('/cloud2.taiwoo.boundless/activity/temple/update', {}, data)
}
// 已购买模板
export const buyTemplate = params => {
  return http.post('/cube-client/activity/temple/shop/success/page', params)
}

/**
 * 钉钉审批
*/
// 修改营销活动是否需要走
export const isApproval = data => {
  return http.put('/cube-client/activity/needProcess2update',  {}, data)
}
// 修改营销活动开始审批
export const startApproval = data => {
  return http.put('/cube-client/activity/startProcess2update', {}, data)
}

// 红辽新的活动发布接口
export const process2Publish = data => {
  return http.put('/cube-client/activity/startProcess2Publish', {}, data)
}

// 集卡模板查看主题
export const getCardTheme = params => {
  return http.get('/cube-client/activity/getCardTheme', {}, params)
}
// 查询队伍列表
export const queryTeam = params => {
  return http.get('/cube-client/activity/quiz/allTeams', {}, params)
}
// 竞猜模板开奖
export const queryWin = params => {
  return http.get('/cube-client/activity/quiz/quizResult', params)
}
// // 查询能否修改开奖结果
export const queryQuizResult = params => {
  return http.get('/cube-client/activity/quiz/queryQuizResult', params)
}

// 查看图片列表
export const picturePage = params => {
  return http.get('/cube-client/giveThumbUp/picturePage', params)
}
// 图片审核
export const auditPicture = params => {
  return http.get('/cube-client/giveThumbUp/auditPicture', params)
}
// 补发查询
export const failRecord = params => {
  return http.post('/cube-client/redPacket/query/failRecord', params)
}
// 手动重试补发红包
export const retrySendOut = params => {
  return http.get('/cube-client/redPacket/retrySendOut', params)
}
// 奖品补发成功
export const queryManuallyRecord = params => {
  return http.post('/cube-client/redPacket/queryManuallyRecord', params)
}
